import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { BackgroundBeams } from '../components/Background';

const ContactPage = () => {
  const heroRef = useRef(null);
  const contactFormRef = useRef(null);
  const contactDetailsRef = useRef(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);  // Add loading state for form submission

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    const serviceID = 'service_p6if8ig';
    const templateID = 'template_n3k84bk';
    const userID = 'mfA2QXRBwk1-LBLQa';

    try {
      await emailjs.send(serviceID, templateID, formData, userID);
      setShowModal(true); // Show success modal
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show an error modal or toast)
    } finally {
      setIsSubmitting(false); // Reset submitting state after attempt
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const heroElement = heroRef.current;
    const contactFormElement = contactFormRef.current;
    const contactDetailsElement = contactDetailsRef.current;

    if (heroElement) observer.observe(heroElement);
    if (contactFormElement) observer.observe(contactFormElement);
    if (contactDetailsElement) observer.observe(contactDetailsElement);

    return () => {
      if (heroElement) observer.unobserve(heroElement);
      if (contactFormElement) observer.unobserve(contactFormElement);
      if (contactDetailsElement) observer.unobserve(contactDetailsElement);
    };
  }, []);

  return (
    <div className="h-screen w-full bg-neutral-950 relative flex flex-col items-center justify-start antialiased overflow-x-hidden">
      {/* Navigation Bar */}
      <div className="w-[90%] fixed top-0 bg-black z-20 flex items-center justify-center md:justify-between px-4 md:px-12 py-4 shadow-md">
        <div className="hidden md:block text-primary font-bold text-5xl">
          Liquidifi
        </div>
        <div className="flex space-x-2 sm:space-x-4">
        <button
      className="bg-primary hover:bg-secondary text-neutral-600 font-bold py-2 px-3 sm:py-2 sm:px-4 md:py-3 md:px-6 text-sm sm:text-base rounded-lg shadow-lg transition-all duration-200 max-w-xs"
      onClick={() => window.location.href = '/'}
    >
      Home
    </button>
    <button
      className="bg-primary hover:bg-secondary text-neutral-600 font-bold py-2 px-3 sm:py-2 sm:px-4 md:py-3 md:px-6 text-sm sm:text-base rounded-lg shadow-lg transition-all duration-200 max-w-xs"
      onClick={() => window.location.href = '/contact'}
    >
      Contact Us
    </button>
          <button
            className="bg-neutral-600 hover:bg-neutral-700 text-white font-bold py-2 px-3 sm:py-2 sm:px-4 md:py-3 md:px-6 text-sm sm:text-base rounded-lg shadow-lg transition-all duration-200 max-w-xs"
            onClick={() => window.open('https://client.alphafunder.io/sign-in', '_blank')}
          >
            Login | Signup
          </button>
        </div>
      </div>
      {/* Background Beams */}
      <BackgroundBeams />

      <section className="text-center my-12" ref={heroRef}>
        <h1 className="text-3xl md:text-5xl font-bold text-primary mb-4 mt-20">Contact Us</h1>
        <p className="text-neutral-400 text-sm md:text-lg max-w-2xl mx-auto">
          We’d love to hear from you! Reach out to us with any questions or feedback.
        </p>
      </section>

      <section className="w-full max-w-4xl mx-auto p-6 flex flex-col md:flex-row gap-8 text-neutral-400 z-50">
        <div className="flex-1" ref={contactFormRef}>
          <h2 className="text-xl font-semibold text-primary mb-4">Get in Touch</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="form-group">
              <label htmlFor="name" className="block text-sm font-medium mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-lg bg-neutral-800 text-neutral-300 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-lg bg-neutral-800 text-neutral-300 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="block text-sm font-medium mb-1">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-lg bg-neutral-800 text-neutral-300 focus:outline-none focus:ring-2 focus:ring-primary"
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}  // Disable button while submitting
              className="bg-primary text-neutral-950 font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-accent transition-all duration-200"
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}  {/* Show loading state */}
            </button>
          </form>
        </div>
        <div className="flex-1" ref={contactDetailsRef}>
          <h2 className="text-xl font-semibold text-primary mb-4">Contact Details</h2>
          <p className="mb-4">
            <strong>Email:</strong> support@alphafunder.io
          </p>
          <p className="mb-4">
            <strong>Address:</strong> Pegasus Building, 210 Amarand Ave, Menlyn Main, Pretoria, 0181
          </p>
          <p>
            <strong>Business Hours:</strong> 8am - 7pm, Monday - Saturday (CAT)
          </p>
        </div>
      </section>

      <section className="mt-12 mb-16 w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-neutral-800 rounded-lg shadow-lg overflow-hidden">
            <h2 className="text-2xl font-semibold text-primary text-center py-6">Our Location</h2>
            <div className="relative">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.534256708452!2d28.281215475664197!3d-25.78594287733663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9561fb5193abb9%3A0x3c3ced8efc967174!2sPegasus%20Building%2C%20210%20Amarand%20Ave%2C%20Menlyn%20Main%2C%20Pretoria%2C%200181!5e0!3m2!1sen!2sza!4v1703199707664!5m2!1sen!2sza&markers=color:red%7Clabel:%E2%80%A2%7C-25.78594287733663,28.281215475664197"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                className="rounded-lg shadow-md"
            ></iframe>
            </div>
        </div>
        </section>



      {showModal && (
        <div className="fixed inset-0 bg-neutral-800 bg-opacity-75 flex items-center justify-center z-30">
          <div className="bg-neutral-900 p-6 rounded-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold text-primary mb-4">Thank You!</h2>
            <p className="text-neutral-400 mb-4">Your message has been sent successfully. We'll get back to you shortly.</p>
            <button
              className="bg-primary text-neutral-950 font-bold py-2 px-4 rounded-lg hover:bg-accent transition-all duration-200"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactPage;


