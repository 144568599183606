import React from 'react';
import { BackgroundBeams } from '../components/Background';
import { FaDiscord, FaLink } from 'react-icons/fa'; // Discord and another icon


const HomePage = () => {
  return (
    <div className="h-screen w-full bg-neutral-950 relative flex flex-col items-center justify-start antialiased overflow-x-hidden">
      
      {/* Navigation Bar */}
      <div className="w-[90%] fixed top-0 bg-black z-20 flex items-center justify-center md:justify-between px-4 md:px-12 py-4 shadow-md">
        <div className="hidden md:block text-primary font-bold text-5xl">
          Liquidifi
        </div>
        <div className="flex space-x-2 sm:space-x-4">
    <button
      className="bg-primary hover:bg-secondary text-neutral-600 font-bold py-2 px-3 sm:py-2 sm:px-4 md:py-3 md:px-6 text-sm sm:text-base rounded-lg shadow-lg transition-all duration-200 max-w-xs"
      onClick={() => window.location.href = '/'}
    >
      Home
    </button>
    <button
      className="bg-primary hover:bg-secondary text-neutral-600 font-bold py-2 px-3 sm:py-2 sm:px-4 md:py-3 md:px-6 text-sm sm:text-base rounded-lg shadow-lg transition-all duration-200 max-w-xs"
      onClick={() => window.location.href = '/contact'}
    >
      Contact Us
    </button>
    <button
      className="bg-neutral-600 hover:bg-neutral-700 text-white font-bold py-2 px-3 sm:py-2 sm:px-4 md:py-3 md:px-6 text-sm sm:text-base rounded-lg shadow-lg transition-all duration-200 max-w-xs"
      onClick={() => window.open('https://client.alphafunder.io/sign-in', '_blank')}
    >
      Login | Signup
    </button>
    
  </div>
      </div>

      {/* Image Section - Showcase Rebranding */}
      <div className="flex items-center space-x-8 mb-8 mt-24">
        {/* First Image */}
        <img 
          src="/images/AF WHITE.png"  // Updated path
          alt="Old Logo" 
          className="w-36 md:w-48 rounded-lg shadow-lg ml-[-2rem]"
        />
        
        {/* Arrow Between Images */}
        <div className="text-primary text-6xl">→</div>
        
        {/* Second Image */}
        <img 
          src="/images/Liq_logo.png"  // Updated path
          alt="New Logo" 
          className="w-20 md:w-40 rounded-lg shadow-lg"
        />
      </div>

      <div className="max-w-7xl mx-auto p-6 text-center">
        {/* Heading */}
        {/* <h1 className="relative z-10 text-3xl md:text-5xl lg:text-6xl bg-clip-text text-transparent bg-gradient-to-b from-primary to-secondary font-bold mb-8">
          Alpha Funder is Pausing and Rebranding to Liquidifi in Q1 2025!
        </h1> */}
        <h1 className="relative z-10 text-3xl md:text-5xl lg:text-6xl font-bold mb-8" style={{ color: '#caa84e' }}>
          Alpha Funder is Pausing and Rebranding to{' '}
          <span className="bg-clip-text text-transparent bg-gradient-to-b from-primary to-secondary">
            Liquidifi
          </span>{' '}
          in Q1 2025!
        </h1>

        {/* First Body Text */}
        <p className="text-neutral-400 max-w-3xl mx-auto my-6 text-sm md:text-lg relative z-10">
          We’ve had an incredible journey so far in Africa, where your overwhelming support has propelled us to heights we never imagined.
          But we’re not stopping here—we’re making bold moves to become even bigger and better so the whole world can experience the value we bring to the table.
          We have thus decided to pause challenge purchases and trading from Nov 26th, and relaunch internationally as Liquidifi in Q1 2025!
        </p>

        {/* List Section with Two Columns */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 text-neutral-400 my-6 text-sm md:text-lg relative z-10">
          {/* Left Column */}
          <div className="space-y-6 text-left">
            <h3 className="font-semibold text-xl text-center text-primary">Why the Pause? 🚀</h3>
            <ul className="list-disc pl-5 space-y-4">
              <li>
                <strong>1️⃣ Pausing to Innovate: </strong> 
                To deliver the absolute best for our traders, we're temporarily pausing operations to build our own in-house tech platform. This will be a game-changer, offering unmatched quality. One of our goals is to be the first-ever prop firm with integrated AI tools!
              </li>
              <li>
                <strong>2️⃣ Leading Liquidity Standards: </strong>
                Liquidity is the lifeblood of payouts and opportunity, and we're working tirelessly to set new industry benchmarks. After all, payouts are the most important aspect for any prop trade! We are committed to becoming industry-leaders in liquidity management, reassuring our wonderful traders that their hard-earned payouts are NOT at risk.
              </li>
              <li>
                <strong>3️⃣ Unlimited Capital Allocation: </strong>
                Yes, you heard right. We are driven more than ever to solve the current problem in the prop firm space - limited capital allocation. Our relaunch will aim to remove the industry standard cap of $200,000 through AI-enabled networking, giving traders the chance to access $millions in funding if they deserve it!
              </li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="space-y-6 text-left">
            <h3 className="font-semibold text-xl text-center text-primary">Rest Assured 🙌</h3>
            <ul className="list-disc pl-5 space-y-4">
              <li>Live accounts will continue to operate as normal until the 20th December 2024.</li>
              <li>Our tech and platforms will remain fully functional during this period.</li>
              <li>From the 20th of December until we relaunch as Liquidifi, our trading dashboard will be disabled to make our own improved version.</li>
              <li>If you have a live account on the 20th Dec, we will allocate you an equivalent account when we relaunch in Q1 2025.</li>
              <li>Any payouts or affiliate commissions due on the 20th Dec will be paid out in full.</li>
            </ul>
          </div>
        </div>

        {/* Second Body Text */}
        <p className="text-neutral-400 max-w-3xl mx-auto my-6 text-sm md:text-lg relative z-10">
          We're gearing up for an epic relaunch under a fresh new identity, bringing innovations that will redefine the industry. Stay tuned—you won't want to miss what's coming next. This pause allows us to focus on building and integrating our new technologies.
        </p>

        {/* Call-to-Action */}
        <p className="text-primary font-semibold max-w-2xl mx-auto mt-6 relative z-10">
          Get ready to witness the next chapter of trading. Follow us and be part of the revolution. 🚀
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8">
          <button
            className="flex items-center bg-primary hover:bg-secondary text-neutral-600 font-bold py-3 px-6 text-lg rounded-lg shadow-lg transition-all duration-200 max-w-xs"
            onClick={() => window.open('https://client.alphafunder.io/sign-in', '_blank')}
          >
            <FaLink className="mr-3 text-xl" />
            Access Live AF Challenges
          </button>

          <button
            className="flex items-center bg-[#5865F2] hover:bg-[#4d54d7] text-white font-bold py-3 px-6 text-lg rounded-lg shadow-lg transition-all duration-200 max-w-xs"
            onClick={() => window.open('https://discord.gg/tbUhpqMDRm', '_blank')}
          >
            <FaDiscord className="mr-3 text-xl" />
            Join our Discord
          </button>
        </div>
        
      </div>

      {/* Background Beams */}
      <BackgroundBeams />
    </div>
  );
};

export default HomePage;